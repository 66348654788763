import { PageSkeleton } from "#components/pages";

export function ContactPage() {
  const title = "Contact Us";
  const heading = "Contact Us";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
      <p>
        Questions: <a href="mailto:contact@kemono.su">contact@kemono.su</a>
      </p>
      <p>
        Legal inquiries: <a href="mailto:legal@kemono.su">legal@kemono.su</a>
      </p>
      <p>
        Advertising inquiries: <a href="mailto:ads@kemono.su">ads@kemono.su</a>
      </p>
    </PageSkeleton>
  );
}
